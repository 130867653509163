.ui.form.form--chat {
  padding: 0;
  display: flex;
  flex-direction: row;
  border-top: 1px solid rgba(0, 0, 0, 0.25);

  > textarea {
    border-radius: 0;
    // border-top-right-radius: 0;
    // border-bottom-right-radius: 0;
    flex: 1 1 auto;
  }
  > .ui.button {
    border-radius: 0;
    margin-right: 0;
    // border-top-left-radius: 0;
    // border-bottom-left-radius: 0;
  }
}

.ui.feed.feed--chat {
  background: #f7f7f7;
  > .Chat--HeaderMessage {
    text-align: center;
    padding: 4px 8px;
    max-width: 350px;
    margin: 12px auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background: #FFF;
    font-size: 0.95em;
  }
  > .event {
    > .content {
      background-color: #fff;
      flex-grow: 0;
      padding: 0.2rem 0.6rem !important;
      margin: 0.1rem 0.5rem 0.5rem !important;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

      & > p {
        margin-bottom: 0.2rem;
      }

      > .summary .image--sponsor {
        max-width: 200px;
        max-height: 50px;
        height: auto;
        display: block;
      }
    }
    &.event--self {
      flex-direction: row-reverse !important;
      > .content {
        background-color: rgba(0, 255, 0, 0.125);
      }
    }

    &.event--sponsor {
      // flex-direction: row-reverse !important;
      > .content {
        background-color: rgba(0, 0, 255, 0.125);
        flex-grow: 1;
      }
    }
  }
}

.Chat {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 auto;

  &--Feed {
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 0; // to prevent postcss from deleting it in shorthand notation.
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding: 1rem !important;
    margin-bottom: 0 !important;
  }

  &--PushDown {
    margin-top: auto;
  }
}
