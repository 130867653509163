.home-block--video {
  .column {
    .ui.segment:first-child {
      margin-bottom: 0;
    }

    .ui.segment.segment--video {
      padding: 0;
      margin-top: 0;
      overflow: hidden;
    }

    .ui.segments {
      > .ui.segment  {
        margin-bottom: 0 !important;
      }
    }
  }
}
