.segment--booking-block {
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5 !important;

  .title {
    color: #9b9898;
    margin-bottom: 5px;
  }

  .label {
    &--choose-slots {
      color: #9b9898 !important;
      font-size: 16px;
      margin-top: 0;
      margin-bottom: 3px;
    }

    &--next-available-slot {
      color: #9b9898 !important;
      font-size: 12px;
      margin-bottom: 0;
    }

    &--date {
      color: #000;
      font-size: 14px;
    }
  }

  .ui.button.primary {
    margin-top: 1em;
  }

  .ui.button.link {
    margin-top: 2em !important;
    margin-bottom: 1em !important;
    margin-right: 2em !important;
    font-size: 0.85em;
  }
}
