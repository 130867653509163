.Home {
  &.ui.grid > .row > .column > .ui.grid {
    // margin: 0;
  }

  &--banner {
    flex: 0 0 auto !important;

    > img {
      width: 100%;
    }
  }
}

.home-block {
  &--socialWall:only-child {
    height: 100%;
    > .column {
      height: 100%;
      > .block--social-wall {
        height: 100%;
      }
    }
  }
}

.ui.segment > .ui.grid > .home-block--logos {
  .ui.card > img {
    background: transparent;
  }
}
