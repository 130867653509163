.block--iframe {
  &.ui.segment {
    padding: 0;
  }
  
  > iframe {
    border: 0;
    width: 100%;
  }
}
