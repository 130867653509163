.card__progress {
  margin-bottom: 8px;
  margin-top: 8px;

  .details {
    display: flex;
    font-size: 12px;

    .label {
      flex: 1;
    }
  }

  .details.workshop-full {
    color: #b44b4b;
    font-weight: bold;
  }


  .ui.active.progress .bar {
    min-width: 0.5em;
  }
}