.UserRegistrationModal {
    &__content {
        text-align: center;

        .field {
            margin-bottom: 10px;
        }

        .please-sign-in {
            margin-bottom: 10px;
        }

        .no-account {
            margin-top: 20px;

            .sign-up {
                margin-left: 10px;
            }
        }
    }
}