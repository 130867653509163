.ui.segment.authentication--container {
  overflow: hidden;

  .field {
    text-align: left;
  }

  .error-message {
    text-align: left;
    background: #fff6f6;
    box-shadow: 0 0 0 1px #e0b4b4 inset;
    padding: 1em 1.5em
  }
}

.authentication--logo {
  max-width: 100%;
}

.authentication {
  &--welcome {
    margin: 1em 0px;
    font-size: 1.5em;
    text-transform: uppercase;
  }
}

.login-form__options {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .form-login {
    &--accept-legal-notice-label {
      margin-left: 10px;
      margin-bottom: 0px;
    }
  }
}

.link--secondary {
  font-style: italic;
  white-space: nowrap;
  color: rgb(128, 128, 128);
}

.link--forgotten-password {
  display: block;
  text-align: right;
  font-style: italic;
  white-space: nowrap;
  color: rgb(128, 128, 128);
}

.text--not-yet-registered {
  text-align: justify;
  font-size: 0.95rem;
}

.Login--MessageWrapper {
  display: flex;
  flex-direction: column;
  margin: 0.5em 0;
}

.Login--MessageWrapper .ui.message {
  text-align: left;
  display: block !important;
}

.scrolling-pane {
  width: 100%;
  text-align: left;
  white-space: nowrap;
  margin-left: 0;
  transition: margin 0.5s ease-in-out;

  &-wrapper {
    overflow: hidden;
    position: relative;
  }

  &__panel {
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
}
