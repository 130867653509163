.ui.segment.Home--banner {
  padding: 0;
  border: 0;
  overflow: hidden;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
}

.block-container--headerless.Home--banner {
  overflow: hidden;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
