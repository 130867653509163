.WorkshopTable {

  .ui.table {
    padding: 0px;
    background: transparent;
  }

  td.time {
    text-align: center;
  }

  &__workshops {
    cursor: pointer;
    padding: 0px;
    vertical-align: top;
  }

  &__workshop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px;

    .actions {
      min-width: 100px;
      max-width: 100px;
      margin-left: 10px;

      .ui.button {
        padding: 6px;
        margin: 4px 0px;
        width: 100%;
        font-weight: 300;
      }
    }
  }
}

@media (max-width: 768px) {
  .WorkshopTable {
    &__days {
      .chevron.down.icon {
        position: absolute;
        right: 4px;
      }
    }

    &__options {
      .ui.button {
        min-width: 100px;
      }
    }

    &__workshop {
      padding: 11px 0rem;
      align-items: center;
    }
  }

  .ui.modal .actions {
    margin: auto;
    text-align: center;

    >.button {

      margin-bottom: 0px !important;
    }
  }
}

// .ui.grid {
//   &.ui.grid.stackable {
//     margin-left: 0px !important;
//     margin-right: 0px !important;
//   }
// }