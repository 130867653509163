.avatars {
  margin-top: 4px;
  margin-bottom: 4px;

  img {
    margin-right: 10px;
  }
}

.speaker {
  &__modal {
    .ui.card.card--speaker {
      width: 100%;
      margin-top: 100px!important;
      border: none;
      box-shadow: none;
    }
  }
}