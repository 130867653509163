.secondary-menu__container {
  display: flex;
  align-items: center;
  position: fixed;
  top: 1vh;
  justify-content: flex-end;
  width: 100%;
  height: 60px;
}

.item--languages {
  &-horizontal {
    span {
      &:not(:last-child):after {
        content: "/";
      }
    }
    a {
      text-transform: uppercase;
      padding: 0.5em 0.5em;
    }
  }
}
