$speakerSize: 128px;
$speakerLightSize:110px;
$speakerLargeSize: 200px;

.ui.card.card--speaker {
  margin-top: $speakerSize / 2;
  text-align: center;
  // flex-grow: 1;
  margin-bottom: 32px;

  .tags {
    margin-top: 10px;
  }

  img.avatar {
    width: $speakerSize;
    height: $speakerSize;
    margin: 0 auto;
    margin-top: -$speakerSize / 2;
    border-radius: 50%;
    display: block;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
      0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);

    &.large {
      width: $speakerLargeSize;
      height: $speakerLargeSize;
      margin-top: -$speakerLargeSize / 2;
    }

    &.light {
      width: $speakerLightSize;
      height: $speakerLightSize;
      margin-top: -$speakerLightSize / 2;

    }
  }

  .content {
    border-top: 0;
    flex: 1 0 auto;

    .field-email {
      word-break: break-all;
    }
  }

  .navlink {
    border-top: 0;
    flex-grow: 0;
    padding-top: 0;
  }
}

@media (max-width: 768px) {
  .ui.card.card--speaker {
    margin-top: $speakerSize / 2 + 10;
  }
}