.Chat--Feed {
  & .Chat--Feed__TextContent {
    display: flex;
    align-items: flex-start;

    & p {
      margin: 0 0 0.5rem;
    }

    & .Chat--Feed__DeleteButton {
      flex: 0 0 auto;
      margin-left: auto;
      margin-right: 0;
      align-self: center;
      opacity: 0;
    }
    &:hover .Chat--Feed__DeleteButton {
      opacity: 1;
    }
  }
}